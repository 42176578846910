require('./js/kendo-ui-license.js');

window.$ = $;
window.jQuery = $;
window.jquery = $;

import App from '6tm-components';
window.App = App;

import 'bootstrap';
import 'jquery-ajax-unobtrusive';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

import Globalize from '6tm-globalize';
import '6tm-globalize/src/cultures/fr-FR';

window.Globalize = Globalize;

import toastr from'toastr';
window.toastr = toastr;

import swal from 'sweetalert';

import JSZip from 'jszip';
window.JSZip = JSZip;

import '@progress/kendo-ui/';
import './js/kendo-ui-license.js';
import '@progress/kendo-ui/js/kendo.grid';

import '@progress/kendo-ui/js/kendo.timezones.js';
import '@progress/kendo-ui/js/kendo.aspnetmvc.js';
import '@progress/kendo-ui/js/cultures/kendo.culture.fr-FR.js';
import '@progress/kendo-ui/js/messages/kendo.messages.fr-FR.js';


var cache = {};

function ImportComponents(components) {
    components.keys().forEach(key => {
        var component = components(key);
        App.Kernel.registerComponent(component.default.name, component.default);
    });
}

ImportComponents(require.context('./js/components/', true, /\.(js)$/));
//ImportComponents(require.context('./js/pages/', true, /\.(js)$/));

App.Kernel.bindComponents($("body"));

import './scss/main.scss';
import './js/main.js';
import './js/6tm-searchbox.js';



