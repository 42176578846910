export default class ImagePicker {

    

    constructor($view) {
        this.view = $view;
        this.settings = {
            root: "/admin/imagebrowser/",
            readAction: "read",
            destroyAction: "destroy",
            createAction: "create",
            thumbnailUrlAction: "thumbnail",
            uploadUrlAction: "upload"
        };
        this.Init();
    }

    Init() {
        var self = this;

        var read = self.settings.root + self.settings.readAction;
        var destroy = self.settings.root + self.settings.destroyAction;
        var create = self.settings.root + self.settings.createAction;
        var thumbnailUrl = self.settings.root + self.settings.thumbnailUrlAction;
        var uploadUrl = self.settings.root + self.settings.uploadUrlAction;

        var link = self.view.find('.js-file-picker-click');
        var associatedId = link.data("input-id");
        var editorId = associatedId + "-textarea";
        var editorSelector = "#" + editorId;

        $(editorSelector).kendoEditor({
            tools: [
                "insertImage"
            ],
            imageBrowser: {
                messages: {
                    dropFilesHere: "Drop files here"
                },
                transport: {
                    type: "imagebrowser-aspnetmvc",
                    read: read,
                    destroy: {
                        url: destroy,
                        type: "POST"
                    },
                    create: {
                        url: create,
                        type: "POST"
                    },
                    thumbnailUrl: thumbnailUrl,
                    uploadUrl: uploadUrl,
                    imageUrl: self.settings.root + "Image?path={0}"
                }
            },
            select: function (e) {
                e.preventDefault();
                var img = $(this.value()).attr('src');
                var rgxp = /\/Image\?path=(.*)$/i;
                var match = rgxp.exec(img);
                var tbValue = decodeURIComponent(match[1]);
                $("#" + associatedId).val(tbValue).change();
                $(editorSelector).data("kendoEditor").value("");
                $("#" + associatedId + "-overview").attr("src", self.settings.root + "Image?path=" + tbValue);
                $("#" + associatedId + "-overview").removeClass("hidden");
                $("#" + associatedId + "-deleted-link").removeClass("hidden");
            }
        });

        $(link).click(function () {
            $(editorSelector).closest('table.k-editor-widget').find(".k-tool-icon").trigger('click');
        });

        $("#" + associatedId + "-deleted-link").click(function () {
            $("#" + associatedId).val("");
            $("#" + associatedId + "-overview").addClass("hidden");
            $(this).addClass("hidden");
        });
    }
}