export default class Navbar {

    constructor($view) {
        this.view = $view;

        this.Init();
    }

    Init() {
        var self = this;

        var uId = self.view.data('uid');

        if (uId !== '00000000-0000-0000-0000-000000000000') {
            $.ajax({
                url: '/admin/utilisateur/recuperercheminvisuel',
                type: 'GET',
                success: function (data) {
                    $("#avatar").attr("src", data);
                }
            });
        }
        else {
            $("#avatar").attr("src", '/dist/public/images/bo/basicAvatar.png');
        }
    }
}