export default class FilePicker {

    constructor($view) {
        this.view = $view;

        this.settings = {
            root: "/admin/fileBrowser/",
            readAction: "read",
            destroyAction: "destroy",
            createAction: "create",
            //thumbnailUrlAction: "thumbnail",
            uploadUrlAction: "upload"
        };

        this.Init();
    }

    Init() {
        var self = this;

        var read = self.settings.root + self.settings.readAction;
        var destroy = self.settings.root + self.settings.destroyAction;
        var create = self.settings.root + self.settings.createAction;
        //var thumbnailUrl = self.settings.root + self.settings.thumbnailUrlAction;
        var uploadUrl = self.settings.root + self.settings.uploadUrlAction;

        var link = self.view.find('.js-file-picker-click');
        var associatedId = link.data("input-id");
        var editorId = associatedId + "-textarea";
        var editorSelector = "#" + editorId;

        $(editorSelector).kendoEditor({
            tools: [
                "insertFile"
            ],
            fileBrowser: {
                messages: {
                    dropFilesHere: "Drop files here"
                },
                transport: {
                    type: "filebrowser-aspnetmvc",
                    read: read,
                    destroy: {
                        url: destroy,
                        type: "POST"
                    },
                    create: {
                        url: create,
                        type: "POST"
                    },
                    //thumbnailUrl: thumbnailUrl,
                    uploadUrl: uploadUrl,
                    fileUrl: self.settings.root + "File?path={0}"
                }
            },
            select: function (e) {
                e.preventDefault();
                var img = $(this.value()).attr('href');
                var rgxp = /\/File\?path=(.*)$/i;
                var match = rgxp.exec(img);
                var tbValue = decodeURIComponent(match[1]);
                $("#" + associatedId).val(tbValue).change();
                $(editorSelector).data("kendoEditor").value("");
            }
        });

        $(link).click(function () {
            $(editorSelector).closest('table.k-editor-widget').find(".k-tool-icon").trigger('click');
        });
    }
}